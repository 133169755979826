<template>
	<base-layout>
		<personal-details @done="finishedStage" />
	</base-layout>
</template>

<script>
import "@/views/Userdetails/PersonalDetails.vue";
import PersonalDetails from "../Userdetails/PersonalDetails.vue";
import { signupWriteProfile } from "@/services/api.js";
import { createToast } from "@/services/toast.js";

import { loadingController } from "@ionic/vue";

export default {
	components: { PersonalDetails },
	methods: {
		async finishedStage() {
			const loading = await loadingController.create({
				cssClass: "loadingClass",
				message: "Creating profile. Please wait.",
			});
			try {
				//this.$router.push("/signin/signup/details2");

				await loading.present();
				await signupWriteProfile(false);
				await loading.dismiss();
				this.$router.push("/signin/signup/details4");
			} catch (e) {
				console.error(e);
				createToast(
					"There was an error creating your profile. Please try again. If the error persists, seek help."
				);
				await loading.dismiss();
			}
		},
	},
};
</script>

<style scoped></style>
