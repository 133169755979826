<template>
	<div class="container">
		<h1 class="sectionHeader">Personal Info</h1>
		<div class="row">
			<ion-input placeholder="First Name" type="text" class="inputBox left" required="true"
				id="firstName"></ion-input>
			<ion-input placeholder="Last Name" type="text" class="inputBox right" required="true" id="lastName"></ion-input>
		</div>
		<h1 class="sectionHeader">Date of Birth</h1>
		<div class="row">
			<ion-input placeholder="Enter date of birth" type="date" class="inputBox full" required="true"
				id="dob"></ion-input>
		</div>
		<h1 class="sectionHeader">Target Race Distance</h1>
		<div class="row">
			<ion-input placeholder="Distance  (km)" type="text" inputmode="numeric" class="inputBox full" required="true"
				id="raceDist"></ion-input>
		</div>
		<div style="display: table; width: fit-content; justify-content: left; /*margin: 0 auto*/">
			<span class="sectionHeader">Current Fitness&nbsp;</span>
			<span>
				<ion-icon @click="showHelpField" :icon="helpCircleOutline" class="helpIcon" id="helpIcon" />
			</span>
		</div>
		<div class="helpText" id="helpArea">
			Input the result of your most recent race / time trial. You should input both the time,
			and the distance. If you are unsure, or you don't have a recent result, estimate it
			conservatively, and we will automatically adjust it over the coming weeks. This should
			not be your goal.
		</div>
		<div class="row">
			<ion-input placeholder="Distance  (km)" type="text" inputmode="numeric" class="inputBox full" required="true"
				id="bestPerformanceDist"></ion-input>
		</div>
		<div class="row">
			<ion-input placeholder="Hours" type="text" inputmode="numeric" class="inputBox thirdLeft" required="true"
				id="bestPerformanceHours"></ion-input>
			<ion-input placeholder="Minutes" type="text" inputmode="numeric" class="inputBox thirdCentre" required="true"
				id="bestPerformanceMinutes"></ion-input>
			<ion-input placeholder="Seconds" type="text" inputmode="numeric" class="inputBox thirdRight" required="true"
				id="bestPerformanceSeconds"></ion-input>
		</div>
		<br />

		<ion-button class="inputBox signInButton" @click="onButtonClick">Confirm</ion-button>
	</div>
</template>

<script>
import { IonInput, IonButton, IonIcon } from "@ionic/vue";
import { set } from "@/services/storage.js";
import { helpCircleOutline } from "ionicons/icons";
import { createToast } from "@/services/toast.js";
import { stringifyQuery } from "vue-router";

/**
 * @param {string} s 
 */
function replaceNonNumeric(s) {
	return s.replace(/[^0-9.]/g, '')
}

export default {
	name: "personal-details",
	components: [IonButton, IonInput, IonIcon],
	data() {
		return {
			showHelp: false,
			kmStrike: false,
			dob: "",
		};
	},
	mounted() {
		document.getElementById("helpIcon").style.backgroundColor =
			"var(--ion-color-light-tint)";
		document.getElementById("helpIcon").style.color = "var(--ion-color-medium-tint)";
	},
	methods: {
		onButtonClick() {
			const dob = document.getElementById("dob").value;
			const perfDistStr = document.getElementById("bestPerformanceDist").value;
			const perfDist = parseInt(replaceNonNumeric(perfDistStr))
			const raceDistStr = document.getElementById("raceDist").value;
			const raceDist = parseInt(replaceNonNumeric(raceDistStr))


			const perfHours = replaceNonNumeric(document.getElementById("bestPerformanceHours").value)
			const perfMins = replaceNonNumeric(document.getElementById("bestPerformanceMinutes").value)
			const perfSecs = replaceNonNumeric(document.getElementById("bestPerformanceSeconds").value)
			if (perfDist < 0.8) {
				createToast("Please enter a performance of greater than 800m");
			} else if ((perfDist > 200 || raceDist > 200) && !this.kmStrike) {
				createToast("Make sure you've entered all distances in km!");
				this.kmStrike = true;
			} else {
				const data = {
					firstName: document.getElementById("firstName").value,
					lastName: document.getElementById("lastName").value,
					weekDist: 0,
					raceDist: raceDist,
					goalDate: "",
					bestPerformanceDist: perfDist,
					bestPerformanceHours:
						perfHours,
					bestPerformanceMinutes:
						perfMins,
					bestPerformanceSeconds:
						perfSecs,
					dob,
				};
				set("PersonalDetails", data);

				this.$emit("done");
			}
		},
		showHelpField() {
			this.showHelp = !this.showHelp;
			if (this.showHelp) {
				document.getElementById("helpIcon").style.backgroundColor =
					"var(--ion-color-secondary)";
				document.getElementById("helpIcon").style.color = "var(--ion-color-light-tint)";
			} else {
				document.getElementById("helpIcon").style.backgroundColor =
					"var(--ion-color-light-tint)";
				document.getElementById("helpIcon").style.color =
					"var(--ion-color-medium-tint)";
			}
			const panel = document.getElementById("helpArea");
			if (panel.style.maxHeight) {
				panel.style.maxHeight = null;
			} else {
				panel.style.maxHeight = panel.scrollHeight + "px";
			}
		},
	},
	setup() {
		return {
			helpCircleOutline,
		};
	},
};
</script>

<style scoped>
.sectionHeader {
	color: white;
	font-size: 30px;
	font-weight: bold;
	top: 0px;
}

.container {
	padding: 10px;
}

.inputBox {
	color: var(--ion-color-light-contrast);
	background: var(--ion-color-light-tint);
	/*border: 2px solid white;*/

	height: 50px;
	box-shadow: 2px;
	--padding-start: 10px;
}

.signInButton {
	bottom: 20px;
	color: black;
	font-weight: bold;
	width: 100%;
	left: 0%;
	position: inherit;
}

.left {
	width: 47.5%;
	left: 0%;
	top: 0%;
}

.right {
	width: 47.5%;
	left: 52.5%;
	top: -50px;
}

.thirdLeft {
	left: 0%;
	width: 30%;
	top: 0px;
}

.thirdCentre {
	left: 35%;
	width: 30%;
	top: -50px;
}

.thirdRight {
	left: 70%;
	width: 30%;
	top: -100px;
}

.full {
	width: 100%;
}

.row {
	width: 100%;
	height: 54px;
	margin-top: 20px;
}

.goalDate {
	color: #ffffff;
	font-size: 24px;
	padding-bottom: 0px;
}

.helpIcon {
	border-radius: 50%;
	position: relative;
	font-size: 20px;
}

.helpText {
	overflow: hidden;
	max-height: 0;
	transition: max-height 0.2s ease-out;
	color: #ffffff;
	font-size: 14px;
}
</style>
